/* ------------------------------------------------
4X:
Accessibility Library
------------------------------------------------*/
// import apex4x from 'apex4x';

/* ------------------------------------------------
APEX LOADER:
We only want to load the required to render each
individal page without a lot of overhead.

"Module loader" detects what modules are rendered
a page and loads only the scripts required to ensue
the modules function properly.
------------------------------------------------*/
const modules = document.querySelectorAll('[data-whatsock]');

console.log('Loading WhatSock...');

(async () => {
    let whatsockComponents = [];
    let whatsockModules = [];

    for(let i = 0, length = modules.length; i < length; i++){

        let component = modules[i].dataset.whatsock;
        let ws_module = modules[i].dataset.whatsockModule;

        if(ws_module) console.log(i, 'ws_module', ws_module);

        if (typeof component == 'string') whatsockComponents.push( component );
        if (typeof ws_module == 'string') whatsockModules.push( ws_module );
    }

    // Remove Duplicates
    whatsockComponents = Array.from(new Set(whatsockComponents));
    whatsockModules = Array.from(new Set(whatsockModules));

    console.log('First check $A:', typeof($A) );

    if(typeof($A) == "function"){
        initA();
    }
    else{
        var tester = window.setInterval(function(){
            console.log('No $A but keep checking...', typeof($A) );

            if(typeof($A) == "function"){
                initA();
                console.log('clearInterval');
                clearInterval(tester);
            }
        }, 500);
    }

    function initA(){
        console.log('initA started', whatsockComponents );
        $A.import( whatsockComponents , {
            props: {},
            defer: true,
            once: true,
            callOnAll: true,
            call: function(props) {
                console.log('00: $A.import\n', props, '\n', whatsockModules);

                (async () => {
                    console.log('01: async');
                    for(let i = 0, length = whatsockModules.length; i < length; i++){
                        console.log('02: Loop', whatsockModules[i]);

                        if (whatsockModules[i] !== 0) {
                            try{
                                console.log('./modules/'+whatsockModules[i]+'.js');

                                await import('./modules/'+whatsockModules[i]+'.js');
                            }
                            catch(err){
                                console.log('ERROR: at ', whatsockModules[i], './modules/'+whatsockModules[i]+'.js');
                                console.error(err)
                            }
                        }
                    }
                })();
            }
        });
    }
})();

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}